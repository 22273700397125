import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import styled from '@emotion/styled';

const ImageBackground = styled(BackgroundImage)`
        height:700px;
        margin-top:90px;
        @media (max-width:768px){
            margin-top:110px;
        }
        @media (max-width:480px){
            margin-top:110px;
            height:568px;
        }
`;

const TextoImagen =styled.div`

        background-image:linear-gradient(to top, rgba(34,49,63,.7),rgba(34,49,63,.7));
       
        color:#f9f7f7;
        height:100%;
        display:flex;
        flex-direction:column;
        flex:1;
        align-items:center;
        justify-content:center;
        h1,p{
            text-align:center;
        }
        h1{
            font-size:6rem;
            @media(max-width:480px){
                font-size:4.5rem !important;
            }
        }
        p{
            font-size:2.5rem;
            @media (max-width:480px){
                font-size:2rem;
            }
        }
        @media (max-width:480px){
            h1{
                font-size:4.5rem;
            }
        }
      
            
        
`

const Banner = () => {
    
    const {image} = useStaticQuery(graphql`
    query{
        image: file(relativePath: {eq:"banner.jpg"}){
        sharp:childImageSharp{
          fluid{
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    `)
    
     

    
    return ( 
        <>
        <ImageBackground tag='section' fluid={image.sharp.fluid} fadeIn='soft'>
            <TextoImagen>
            <h1>Estudio Contable Lemberger</h1>
               <p>Servicios integrales en materia impositiva, contable, laboral, administrativa y societaria.</p>
            </TextoImagen>
            
        </ImageBackground>
        </>
     );
}
 
export default Banner;
import React from "react"
import Layout from '../components/layout';
import Banner from '../components/banner/banner';
import Presentacion from '../components/index/presentacion';
import ContactForm from "../components/index/contactForm/contactForm";



const IndexPage = () => {


  return(
    <Layout>
      <Banner/>
      <Presentacion/>
      <ContactForm/>
    
 
    </Layout>
  )

}
  
  


export default IndexPage

import React from 'react';
import styled from '@emotion/styled';
import {Link} from 'gatsby'


const Conocenos = styled.section`
    
    
    padding:2rem;
   

    
    .presentacion{
        margin: 50px auto; 
        display:block;
        max-width:980px;
        line-height:1.8;
        h2{
            
            text-align:center;
            font-size:4.5rem;
            color:#3f72af;
        }
        p{
            font-size:1.8rem;
            text-align:center;
            
        }
        span{
            
            font-weight:bold;

        }
        a{
            font-weight:bold;
            color:white;
            padding:1rem;
            margin:.5rem;
            background-color:#3f72af;
            text-align:center;
            border-radius:1rem;
            transition:all .9s;

            :hover{
                background-color:#dbe2ef;
                color:#3f72af;
            }
        }
        @media (max-width:480px){
            h2{
                font-size:4rem;
            }
            p{
                font-size:2rem;
            }
        }
    }   
`

const Presentacion = () => {
    return ( 
        <Conocenos>
            
            <div className='presentacion'>
                <h2>Nuestro Estudio</h2>
                <p><span>Estudio Contable Lemberger</span> se encuentra ubicado en la ciudad de Mar del Plata, desempeñando durante años el asesoramiento profesional y consultoría profesional e integral a una amplia gama de clientes.</p>
                <p>Entre nuestros valores se destacan la <span>Responsabilidad, Compromiso, Respecto, Eficiencia, Calidad y Ética Profesional</span>, siendo estos los que definen la esencia de nuestro Estudio.</p>
                <p>La característica principal que poseemos es brindar un servicio altamente <span>calificado y personalizado</span> que se adapta a las necesidades de cada cliente complementándolo para maximizar sus fortalezas y trabajar sobre sus debilidades. De esta manera trabajamos de forma conjunta para lograr el progreso del negocio.</p>
                <p>Hemos participado activamente en la constitución y desarrollo de emprendimientos y de nuevas unidades comerciales, realizado por personas y reconocidas empresas de la ciudad.</p>
                <p>El alto grado de organización nos permite asistir en tiempo y forma a cada uno de nuestros clientes, cumpliendo eficientemente los requerimientos impositivos, laborales y contables.</p>
                <p>Conozca nuestros <Link to={'/servicios'}>Servicios</Link> </p>


            </div>
        
        </Conocenos>
     );
}
 
export default Presentacion;